body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.search {
    z-index: 1;
    position: fixed;
    top: 5px;
    left: 5px;
}

.search-field {
    width: 320px;
    padding: 5px;
}

ul {
    background: white;
    border: 1px solid darkgrey;
    overflow: auto;
    padding: 0px;
    margin: 0;
    max-height: 200px;
    overflow: auto;
}

.box {
    cursor: pointer;
    padding: 5px;
}

.box:hover {
    background-color: #269abc;
    color: rgb(136, 248, 9);
}

.button {
    text-align: center;
    padding: 5px;
    background-color: white;
    border: 1px darkgrey solid;
    cursor: pointer;
    width: 334px;
}
